import config from '@/utils/config'
import axios from 'axios'
import RequestAdmin from '@/utils/request'
import { getDatosInformeAmpliacion } from '@/utils/catalogos'
import { formatDate } from '@/utils/fechas'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line no-unused-vars
function getNameMonth(date) {
  const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
  ]
  const fecha = new Date(date)
  return monthNames[fecha.getMonth()]
}

function getMesAmpliacion(ampliacion) {
  if (ampliacion.mesInformeAmpliacion) {
    return ampliacion.mesInformeAmpliacion.nombre
  }
  return getNameMonth(ampliacion.fechaRealInstalacion ? ampliacion.fechaRealInstalacion : ampliacion.fechaInstalacion)
}

// eslint-disable-next-line no-unused-vars
function getOrdenAmpliacion(ampliacion, date) {
  if (ampliacion.ordenAmpliacion) return ampliacion.ordenAmpliacion

  const fecha = new Date(date)
  let mes = fecha.getMonth() + 1
  mes = mes < 10 ? mes = `0${mes}` : mes

  return `${mes}-${fecha.getFullYear()}`
}

function getTituloTrabajo(item) {
  if (item === null) return 'Metros Cable'
  const texto = item.tipo.nombre.split('.')
  return texto.length > 1 ? texto[1] : texto[0]
}

function getUrlImage(ampliacion, item, id) {
  if (item !== null) {
    return item.tracking[id].url
  }
  if (id === 0) return ampliacion.tracking.filter(t => t.tipo === 'Ingreso de Ampliación')[0].url
  if (id === 1) return ampliacion.tracking.filter(t => t.tipo === 'Inicio Inspección')[0].url
  return ampliacion.tracking.filter(t => t.tipo === 'Finalización Ampliación')[0].url
}

// eslint-disable-next-line import/prefer-default-export
export function sendFile(file, type, ruta) {
  const userData = getUserData()
  const url = `${config.URL_BASE}files/${type}/${ruta}`
  const formData = new FormData()
  formData.append('file', file)
  formData.append('company', userData.company.name)
  return axios.post(`${url}`, formData, {
  }).then(res => res).catch(err => {
    console.error('error cargando archivo', err)
    return null
  })
}

export async function saveImagen(imagen, urlBucket, usuario, tipo, localizacion, comentarios = '') {
  const file = await sendFile(imagen, 'images', urlBucket)
  if (!file) return file
  const item = file.data
  item.isMobile = window.screen.width <= 760
  item.localization = localizacion
  // eslint-disable-next-line no-nested-ternary
  item.tipo = tipo
  item.comentarios = comentarios
  item.fechaHora = new Date()
  item.usuario = {
    id: usuario.id,
    nombre: `${usuario.firstName} ${usuario.firstSurname}`,
  }
  return item
}

function tieneMetrosCable(ampliacion) {
  // eslint-disable-next-line consistent-return
  ampliacion.items.forEach(item => {
    if (item.item.id === 0) return true
  })
  return false
}

function getDataTracking(ampliacion, status) {
  const items = ampliacion.tracking.filter(t => t.tipo === status)
  if (items.length > 0) {
    return {
      url: items[0].url,
      isMobile: items[0].isMobile,
    }
  }
  return {
    url: '',
    isMobile: false,
  }
}

export async function ordernarItemsAmpliacion(ampliacion, lista = null) {
  let listado = lista
  if (!listado) listado = await getDatosInformeAmpliacion()
  const items = []
  if (ampliacion.metros > 0 && !tieneMetrosCable(ampliacion)) {
    const newItem = {
      id: ampliacion.items.length + 1,
      tipo: {
        id: 0,
        catalogo: 'unidades_varias',
        nombre: 'Metros de Cable',
      },
      item: {
        id: 0,
        nombre: 'Metros de Cable',
        precio: {
          id: 0,
          costo: 0,
          precioVenta: 0,
        },
      },
      tracking: [
        getDataTracking(ampliacion, 'Ingreso de Ampliación'),
        getDataTracking(ampliacion, 'Inicio Inspección'),
        getDataTracking(ampliacion, 'Finalización Ampliación'),
      ],
    }
    ampliacion.items.push(newItem)
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const detalle of listado) {
    const filtro = ampliacion.items.filter(a => a.tipo.catalogo === detalle.tablaOrigen && a.item.id === detalle.idOrigen)
    if (filtro.length > 0) {
      const item = filtro[0]
      item.correlativo = detalle.correlativo
      item.precio = detalle.correlativo === 12 ? item.item.precio.precioVenta * ampliacion.metros : item.item.precio.precioVenta
      item.tipo.nombre = `${detalle.correlativo}. ${detalle.nombreReporte}`
      item.tipo.indicador = detalle.indicadorReporte
      items.push(item)
    }
  }
  return items
}

export async function generarResumenAmplicacion(amplacion, items) {
  let html = `<!DOCTYPE html>
              <html lang="es">
              <head>
                  <meta charset="utf-8">
                  <meta name="factura" content="width=device-width, initial-scale=1">
                  <title>Factura</title>
                  <meta charset="utf-8">
              </head>
              <style>
                  @page {
                    margin-top: 1.1811in;
                    margin-bottom: 0.7874in;
                    margin-left: 0.7874in;
                    margin-right: 0.7874in;
                  }
                  .headerLogos{
                      width: 100%;
                      height: 2px;
                      margin-top: 25px;
                      margin-bottom: 20px;
                      border-collapse: collapse;
                  }
                  .letrasLogo{
                      background-color: #376FA8;
                      padding-left: 20px;
                      padding-right: 20px;
                      padding-top: 20px;
                      padding-bottom: 20px;
                      color: white;
                      font-family: sans-serif;
                      text-align: justify;
                  }
                  .segunda{
                      border-collapse: collapse;
                      width: 100%;
                  }
                  .tercera{
                      border-collapse: collapse;
                      width: 100%;
                      border: 1px solid black;
                  }
                  .items{
                      border-collapse: collapse;
                      width: 100%;
                      border: 1px solid black;
                  }
                  .second{
                      background-color: #376FA8;
                      color: white;
                      font-family: sans-serif;
                  }
                  th{
                      border: 1px solid black;
                      margin-right: 25px;
                      margin-left: 25px;
                      font-family: sans-serif;
                      font-weight: bold;
                      font-size: 1.5em;
                  }
                  td{
                      border: 1px solid black;
                      margin-right: auto;
                      margin-left: auto;
                      font-family: sans-serif;
                      text-align: center;
                      font-size: 1.4em;
                  }
                  .info{
                      text-align: center;
                      border: 1px solid black;
                  }
                  .linea{
                      border-top: 1px solid black;
                      max-width: 470px;
                      display: inline-block;
                      vertical-align: top;
                      margin-top: 250px;
                      font-size: small;
                      font-family: arial;
                      margin-left: 30px;
                  }
                  .row{
                      text-align: center;
                  }
                  .lineaFinal{
                      padding: 5px;
                      background-color: #376FA8;
                  }
                  .textoFooter {
                      font-size: 1.5em;
                  }
              </style>
              <body>
                  <table class="headerLogos">
                      <tr>
                        <th width="33.33%" height="40px">
                          <img style="max-width: 400px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
                        </th>
                        <th width="33.33%" height="40px" class="letrasLogo"> <center>CRECIMIENDO DE LA RED<br>ALUMBRADO PÚBLICO</center></th>
                        <th width="33.33%" height="40px">
                          <img style="max-width: 400px;" src="https://storage.googleapis.com/apvn-files/imagenes/logos/logo-muni.png" alt="logomuni"/>
                        </th>
                      </tr>
                    </table>
                    <table class="segunda">
                      <tr>
                        <th style="width: 10%"class="second">Orden No.</th>
                        <th style="width: 10%">${getOrdenAmpliacion(amplacion, amplacion.tracking[0].fechaHora)}</th>
                        <th style="width: 20%" class="second">Fecha de Instalación</th>
                        <th style="width: 20%">${formatDate(amplacion.fechaRealInstalacion ? amplacion.fechaRealInstalacion : amplacion.fechaInstalacion, '/')}</th>
                        <th style="width: 30%" class="second">Coordenadas</th>
                        <th style="width: 10%" class="second">Zona</th>
                      </tr>
                    </table>
                    <table class="segunda">
                      <tr>
                          <th style="width: 10%" class="second">Item</th>
                          <th style="width: 10%">${amplacion.noItem ? amplacion.noItem : 1}</th>
                          <th style="width: 10%" class="second">Poste No.</th>
                          <th style="width: 30%">${amplacion.poste}</th>
                          <th style="width: 15%" class="second">Latitud</th>
                          <th style="width: 15%" class="second">Longitud</th>
                          <th style="width: 10%">${amplacion.localidad.zona}</th>
                        </tr>
                      </table>
                      <table class="segunda">
                        <tr>
                          <th style="width: 10%" class="second">Dirección de <br> Referencia</th>
                          <th style="width: 50%" >${amplacion.calleAvenida.toUpperCase()} ${amplacion.numeral.toUpperCase()} ZONA ${amplacion.localidad.zona} ${amplacion.localidad.nombre}</th>
                          <th style="width: 15%">${amplacion.latitud}</th>
                          <th style="width: 15%" >${amplacion.longitud}</th>
                          <th style="width: 10%"></th>
                        </tr>
                    </table>
                    <br>
                    <table class="tercera">
                      <tr>
                        <th style="width: 20%"class="second">Unidades Constructivas</th>
                        <th style="width: 20%"class="second">Descripción</th>
                        <th style="width: 20%"class="second">Cantidades</th>
                        <th style="width: 20%"class="second">Fecha de Instalación</th>
                      </tr>
                    </table>
                    <table class="items"> `

  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    html += `<tr>
                      <td class="info" style="width: 25%">${item.tipo.nombre}</td>
                      <td class="info" style="width: 25%">${item.item.nombre}</td>
                      <td class="info" style="width: 25%">${item.item.id === 0 ? amplacion.metros : '1'}</td>
                      <td class="info" style="width: 25%">${formatDate(amplacion.fechaRealInstalacion ? amplacion.fechaRealInstalacion : amplacion.fechaInstalacion, '/')}</td>
                      </tr>
                  `
  }
  html += `      <tr>
                          <td class="lineaFinal" style="width: 25%"></td>
                          <td class="lineaFinal" style="width: 25%"></td>
                          <td class="lineaFinal" style="width: 25%"></td>
                          <td class="lineaFinal" style="width: 25%"></td>
                          </tr>
                    </table>
                    <div class="row">
                      <div class="linea">
                        <p class="textoFooter">ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
                      </div>
                      <div class="linea">
                        <p class="textoFooter">SUPERVISOR MUNICIPAL DIRECCIÓN DE SERVICIOS PÚBLICOS MUNICIPALIDAD DE VILLA NUEVA</p>
                      </div>
                      <div class="linea">
                        <p class="textoFooter">AUTORIDAD DE APLICACIÓN Y CONTROL MUNICIPALIDAD DE VILLA NUEVA</p>
                      </div>
              </body>
              </html>`

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '3cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}

export async function generatePDF(amplacion, item) {
  const html = `<html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>Prueba</title>
  <meta charset="utf-8">
  <style>
      @page {
        margin-top: 1.1811in;
        margin-bottom: 0.7874in;
        margin-left: 0.7874in;
        margin-right: 0.7874in;
      }
      .primera {
          width: 100%;
          height: 2px;
          margin-top: 25px;
          margin-bottom: 20px;
          border-collapse: collapse;
      }
      .first {
          background-color: #376FA8;
          padding-top: 20px;
          padding-bottom: 20px;
          color: white;
          text-align: justify;
          font-family: sans-serif;
      }
      .segunda {
          border-collapse: collapse;
          width: 100%;
      }
      .tercera {
          border-collapse: collapse;
          width: 100%;
          border: 1px solid black;
      }
      .second {
          background-color: #376FA8;
          padding-top: 10px;
          padding-bottom: 10px;
          color: white;
          font-family: sans-serif;
      }
      .si {
          width: 100%;
          background-color: #376FA8;
          color: white;
          font-family: sans-serif;
      }
      th {
          border: 1px solid black;
          margin-right: 25px;
          margin-left: 25px;
          font-family: sans-serif;
          font-weight: bold;
          font-size: 1.5em;
      }
      .info {
          padding: 2px;
          text-align: center;
          width: 25%;
          border: 1px solid black;
      }
      td {
          text-align: center;
          size: 500px;
          margin-right: 25px;
          margin-left: 25px;
          margin-top: 25px;
          font-size: 1.5em;
      }
      .Imagenes {
          width:100%;
          text-align: left;
          border-collapse: separate;
          border-spacing:  20px;
          margin-top: -30px;
      }
      .linea {
          border-top: 1px solid black;
          max-width: 470px;
          display: inline-block;
          vertical-align: top;
          margin-top: 100px;
          font-size: small;
          font-family: arial;
          margin-left: 30px;
      }
      .row {
          text-align: center;
      }
      .thTitulos {
          font-size: 1.5em;
      }
      .textoFooter {
          font-size: 1.5em;
      }
      .indicadorTiutulo {
          font-size: 1.3em;
      }
      .infoGeneralTitulo {
          font-size: 1.8em;
      }
  </style>
</head>
<body>
<table class="primera">
  <tr>
    <th width="33.33%" height="10px">
      <img style="max-width: 400px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
    <th width="33.33%" height="10px" class="first"> <center>Reporte fotográfico - Crecimiento de la red <br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="10px">
      <img style="max-width: 400px;" src="https://storage.googleapis.com/apvn-files/imagenes/logos/logo-muni.png" alt="logomuni"/>
    </th>
  </tr>
</table>
<table class="segunda">
  <tr>
    <th width="15.22%" class="second">Trabajo a Realizar</th>
    <th width="10.22%">${getTituloTrabajo(item)}</th>
    <th width="15.22%" class="second">Mes de Ampliación</th>
    <th width="8.22%">${getMesAmpliacion(amplacion)}</th>
  </tr>
</table>
<table class="tercera">
  <h2 class="infoGeneralTitulo">Información General</h2>
  <tr class="si">
    <th class="thTitulos" style="width: 10%">Item</th>
    <th class="thTitulos" style="width: 10%">No. de Poste</th>
    <th class="thTitulos" style="width: 30%">Dirección</th>
    <th class="thTitulos" style="width: 20%">Centro Poblado</th>
    <th class="thTitulos" style="width: 10%">Zona</th>
    <th class="thTitulos" style="width: 20%">Orden Ampliación</th>
  </tr>
  <td class="info" style="width: 10%">${amplacion.noItem ? amplacion.noItem : 1}</td>
  <td class="info" style="width: 10%">${amplacion.poste}</td>
  <td class="info" style="width: 30%">${amplacion.calleAvenida} ${amplacion.numeral}</td>
  <td class="info" style="width: 20%">${amplacion.localidad.nombre}</td>
  <td class="info" style="width: 10%">${amplacion.localidad.zona}</td>
  <td class="info" style="width: 20%">${getOrdenAmpliacion(amplacion, amplacion.tracking[0].fechaHora)}</td>
</table>
<h3 class="indicadorTiutulo">${item.tipo.indicador}</h3>
<table class="Imagenes">
  <td><img src="${getUrlImage(amplacion, item, 0)}" alt="1" width="620" height="620"></td>
  <td><img src="${getUrlImage(amplacion, item, 1)}" alt="2" width="620" height="620"></td>
  <td><img src="${getUrlImage(amplacion, item, 2)}" alt="3" width="620" height="620"></td>
</table>
<div class="row">
  <div class="linea">
    <p class="textoFooter">ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
  <div class="linea">
    <p class="textoFooter">SUPERVISOR MUNICIPAL DIRECCIÓN DE SERVICIOS PÚBLICOS<br> MUNICIPALIDAD DE VILLA NUEVA</p>
  </div>
  <div class="linea">
    <p class="textoFooter">AUTORIDAD DE APLICACIÓN Y CONTROL MUNICIPALIDAD DE VILLA NUEVA</p>
  </div>
  </div>
</body>
</html>
`
  const body = {
    tamaño: 'A2',
    margenes: {
      top: '3cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
