<template>
  <div>
    <b-overlay :show="loading">
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        v-b-tooltip.hover.v-warning
        title="Descargar Reporte Excel"
        variant="warning"
        class="btn-icon"
        @click="generarExcel()"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, VBPopover, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'

const xl = require('excel4node')

export default {
  components: {
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    tituloPrincipal: {
      type: Object,
      required: true,
    },
    tituloSecundario: {
      type: Object,
      required: true,
    },
    subtitulo: {
      type: Object,
      required: true,
    },
    tituloDescarga: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async generarExcel() {
      this.loading = true
      try {
        const wb = new xl.Workbook()
        const ws = wb.addWorksheet('Hoja 1')

        this.columns.forEach((column, index) => {
          ws.cell(6, index + 1).string(column.label).style(wb.createStyle(column.style))
          ws.column(index + 1).setWidth(Number(column.width))
          // si tuvieras el style
          // ws.cell(6, index + 1).string(column.label).style(column.style)
        })

        this.rows.forEach((row, rowIndex) => {
          let columnIndex = 0
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const property in row) {
            const atributo = row[property]

            if (atributo.type === 'string') {
              ws.cell(rowIndex + 7, columnIndex + 1).string(String(atributo.value)).style(wb.createStyle(atributo.style))
            } else if (atributo.type === 'number') {
              ws.cell(rowIndex + 7, columnIndex + 1).number(Number(atributo.value)).style(atributo.style)
            } else if (atributo.type === 'date') {
              if (atributo.value) ws.cell(rowIndex + 7, columnIndex + 1).date(new Date(atributo.value)).style(atributo.style)
              else ws.cell(rowIndex + 7, columnIndex + 1).string('').style(wb.createStyle(atributo.style))
            }// falta currency,
            columnIndex += 1
          }
        })

        ws.cell(1, 1, 2, this.columns.length, true).string(`${this.tituloPrincipal.titulo ? this.tituloPrincipal.titulo : ''}`).style(this.tituloPrincipal.style)
        ws.cell(3, 1, 3, this.columns.length, true).string(`${this.tituloSecundario.titulo ? this.tituloSecundario.titulo : ''}`).style(this.tituloSecundario.style)
        ws.cell(4, 1, 4, this.columns.length, true).string(`${this.subtitulo.subtitulo ? this.subtitulo.subtitulo : ''}`).style(this.subtitulo.style)

        const buffer = await wb.writeToBuffer()
        const string = buffer.toString('base64')
        const linkSource = `data:application/xlsx;base64,${string}`
        const downloadLink = document.createElement('a')
        const fileName = `${this.tituloDescarga}.xlsx`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        notificacionInformativa(`${this.tituloDescarga}`, 'Documento Excel Descargado!')
      } catch (error) {
        notificacionError('Error descargando Excel')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
