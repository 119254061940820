// eslint-disable-next-line import/prefer-default-export

export function sortFn(x, y, col, rowX, rowY) {
  const aValue = this.getListadoTrabajos(rowX.trabajosRealizados)
  const bValue = this.getListadoTrabajos(rowY.trabajosRealizados)

  return aValue.localeCompare(bValue)
}
export function isMobile() {
  return window.screen.width <= 760
}

export function datosBrigada(usuarioCreacion) {
  if (usuarioCreacion) {
    const usuario = usuarioCreacion
    return {
      id: usuario.id,
      email: usuario.email,
      telefono: usuario.telefono,
      nombre: `${usuario.firstName} ${usuario.firstSurname}`,
    }
  }
  return null
}

export function calcularTiempoTranscurrido(ticket) {
  const ahora = new Date()
  const ingreso = new Date(ticket.fechaCreacion)
  return Math.floor((ahora - ingreso) / 60000)
}
export function calcularMinutosTranscurridos(item, ultimoSeguimiento) {
  const fechaHora = new Date(item.fechaHora)
  if (!ultimoSeguimiento) {
    return '0'
  }
  const minutos = Math.floor((fechaHora - ultimoSeguimiento) / 60000)
  return minutos.toString()
}
export function formatMinutosTranscurridos(item, ultimoSeguimiento) {
  const minutos = calcularMinutosTranscurridos(item, ultimoSeguimiento)
  if (minutos >= 60) {
    const horas = Math.floor(minutos / 60)
    const minutosRestantes = minutos % 60
    return `${horas} ${horas === 1 ? 'HORA' : 'HORAS'} ${minutosRestantes} MIN`
  }
  return `${minutos} MIN`
}

export function getColorVariant(item, ultimoSeguimiento) {
  const minutos = calcularMinutosTranscurridos(item, ultimoSeguimiento)
  return minutos >= 60 ? 'light-danger' : 'light-success'
}

export function tiempoTranscurridoTicket(fechaCreacion) {
  const ahora = new Date()
  const ingreso = new Date(fechaCreacion)
  const diferenciaEnMinutos = Math.floor((ahora - ingreso) / 60000)
  return diferenciaEnMinutos
}
