import { sendFile } from '@/utils/files'

// eslint-disable-next-line import/prefer-default-export
export async function saveImagen(urlBucket, localization, foto, tipo, comentarios, usuario, isMobile) {
  try {
    const imagen = await sendFile(foto, 'images', urlBucket)
    if (imagen !== null) {
      const file = imagen.data
      file.localization = localization
      file.tipo = tipo
      file.fechaHora = new Date()
      file.comentarios = comentarios
      file.usuario = usuario
      file.isMobile = isMobile
      return file
    }
  } catch (err) {
    console.error('Error almancenando imagen ', err)
  }
  return null
}
