<template>
  <b-row>
    <!-- No. Poste -->
    <b-col cols="6">
      <b-form-group
        label="No. Poste"
        label-for="correlativo"
      >
        <b-form-input
          v-model="poste.numeroPoste"
          name="correlativo"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Serie Lampara -->
    <b-col cols="6">
      <b-form-group
        label="Serie Lámpara"
        label-for="serieLampara"
      >
        <b-form-input
          v-model="poste.serieLampara"
          name="serieLampara"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Grupo-->
    <b-col cols="6">
      <b-form-group
        label="Grupo"
        label-for="grupo"
      >
        <b-form-input
          v-model="grupo.nombre"
          label="grupo"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Potencia -->
    <b-col cols="6">
      <b-form-group
        label="Potencia"
        label-for="potencia"
      >
        <b-form-input
          v-model="poste.potencia"
          label="potencia"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Luminaria -->
    <b-col cols="6">
      <b-form-group
        label="Luminaria"
        label-for="tipoLuminaria"
      >
        <b-form-input
          v-model="poste.tipoLuminaria"
          label="tipoLuminaria"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Tecnología -->
    <b-col cols="6">
      <b-form-group
        label="Tecnología"
        label-for="tecnologiaLampara"
      >
        <b-form-input
          v-model="tecnologiaLampara.nombre"
          label="tecnologiaLampara"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Módelo -->
    <b-col cols="6">
      <b-form-group
        label="Clase / Modelo"
        label-for="modeloLampara"
      >
        <b-form-input
          v-model="modeloLampara.nombre"
          label="modeloLampara"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Contador -->
    <b-col cols="6">
      <b-form-group
        label="Contador"
        label-for="contador"
      >
        <b-form-input
          v-model="poste.numeroContador"
          label="contador"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Correlativo-->
    <b-col cols="6">
      <b-form-group
        label="Correlativo"
        label-for="correlativo"
      >
        <b-form-input
          v-model="poste.correlativo"
          label="correlativo"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Red Comunicacion -->
    <b-col cols="6">
      <b-form-group
        label="Red Comunicación"
        label-for="redComunicacion"
      >
        <b-form-input
          v-model="poste.redComunicacion"
          label="redComunicacion"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>

    <!-- Localidad -->
    <b-col cols="9">
      <b-form-group
        label="Localidad"
        label-for="localidad"
      >
        <b-form-input
          v-model="localidad.nombre"
          label="localidad"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Zona -->
    <b-col cols="3">
      <b-form-group
        label="Zona"
        label-for="zona"
      >
        <b-form-input
          v-model="localidad.zona"
          label="zona"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Calle Av -->
    <b-col cols="6">
      <b-form-group
        label="Calle / Avenida"
        label-for="calleAvenida"
      >
        <b-form-input
          v-model="poste.calleAvenida"
          label="calleAvenida"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>
    <!-- Numeral -->
    <b-col cols="6">
      <b-form-group
        label="Numeral"
        label-for="numeral"
      >
        <b-form-input
          v-model="poste.numeral"
          label="numeral"
          :disabled="editable"
        />
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { getItem } from '@/utils/catalogos'
import { getPoste } from '@/utils/postes'
import { push } from 'echarts/lib/component/dataZoom/history'

export default {
  name: 'DetailPunto',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    punto: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    objLocalidad: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      poste: {},
      localidad: '',
      modeloLampara: '',
      grupo: '',
      tecnologiaLampara: '',
      propiedad: '',
      editable: true,

    }
  },
  async created() {
    await this.cargarItem()
  },
  methods: {
    async cargarItem() {
      try {
        const result = this.punto ? this.punto : await getPoste(this.id)
        if (result !== null) {
          this.poste = result
          if (this.poste !== null) {
            if (!this.poste.files) this.poste.files = []
            this.localidad = this.poste.obj_localidad || this.objLocalidad
            delete this.poste.obj_localidad

            const modeloId = typeof this.poste.modeloLampara === 'object' ? this.poste.modeloLampara.id : this.poste.modeloLampara
            const modelo = modeloId ? getItem(3, modeloId) : null
            const tecnologiaId = typeof this.poste.tecnologiaLampara === 'object' ? this.poste.tecnologiaLampara.id : this.poste.tecnologiaLampara
            const tecnologia = tecnologiaId ? getItem(4, tecnologiaId) : null
            const grupo = this.poste.objGrupo ? this.poste.objGrupo : getItem(12, this.poste.grupo)

            Promise.all([modelo, tecnologia, grupo]).then(value => {
              // eslint-disable-next-line prefer-destructuring
              this.modeloLampara = value[0]
              if (!this.modeloLampara) this.modeloLampara = { nombre: '' }
              // eslint-disable-next-line prefer-destructuring
              this.tecnologiaLampara = value[1]
              if (!this.tecnologiaLampara) this.tecnologiaLampara = { nombre: '' }
              // eslint-disable-next-line prefer-destructuring
              this.grupo = value[2]
              if (!this.grupo) this.grupo = { nombre: '' }
            })
          }
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.poste = { }
      }
    },
  },
}
</script>

<style scoped>

</style>
