import RequestAdmin from '@/utils/request'
import config from '@/utils/config'

const urlRutas = `${config.URL_BASE}inspeccion-ruta`
const urls = {
  1: `${config.URL_BASE}tipo-postes`,
  2: `${config.URL_BASE}status-lamparas`,
  3: `${config.URL_BASE}clase-lamparas`,
  4: `${config.URL_BASE}tecnologia-luminarias`,
  5: `${config.URL_BASE}marcas`,
  6: `${config.URL_BASE}propiedades`,
  7: `${config.URL_BASE}tipo-fallas`,
  8: `${urlRutas}/asignar`,
  9: `${config.URL_BASE}tipo-lamparas`,
  10: `${config.URL_BASE}unidades-varias`,
  11: `${config.URL_BASE}historico-precios`,
  12: `${config.URL_BASE}grupos`,
  13: `${config.URL_BASE}sistema-comunicaciones`,
  14: `${config.URL_BASE}tipo-brazos`,
  15: `${config.URL_BASE}tipo-falla-bt`,
  16: `${config.URL_BASE}trabajos-conservacion`,
  17: `${config.URL_BASE}trabajos-brecha`,
  18: `${config.URL_BASE}trabajos-gateway`,
  19: `${config.URL_BASE}trabajos-gabinete`,
  20: `${config.URL_BASE}trabajos-ticket`,
  21: `${config.URL_BASE}fallas-lamparas`,
  22: `${config.URL_BASE}diagnostico-lamparas`,
  23: `${config.URL_BASE}pruebas-lamparas`,
  24: `${config.URL_BASE}reparaciones_laboratorio`,
  25: `${config.URL_BASE}potencia-lamparas`,
  26: `${config.URL_BASE}tipo-herrajes`,
  27: `${config.URL_BASE}tamano-brazos`,
  28: `${config.URL_BASE}tipo-conexions`,
  29: `${config.URL_BASE}tipo-poste-levantamiento`,
  30: `${config.URL_BASE}unidades-varias`,
  31: `${config.URL_BASE}tipo-dispositivos`,
  32: `${config.URL_BASE}tamanos-unidades`,
  33: `${config.URL_BASE}unidades-varias`,
  34: `${config.URL_BASE}tipo-lampara-bodegas`,
}

function getUrlTipo(tipo) {
  return urls[tipo] || ''
}

export async function getPreciosByCatalogo(table, id) {
  try {
    const request = new RequestAdmin()
    const filter = {
      where: {
        tablaOrigen: table,
        idOrigen: id,
      },
    }
    return request.executeGet(
      `${getUrlTipo(11)}?filter=${JSON.stringify(filter)}`,
    )
  } catch (err) {
    console.error('Error obtener el historico de precios', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getlistado(
  tipo,
  catalogo,
  mostrarTodos = true,
  usuario = null,
) {
  try {
    const request = new RequestAdmin()
    let url = getUrlTipo(tipo)
    if (tipo === 8) {
      url = urlRutas
      const filter = {
        where: {
          active: true,
          companyId: usuario.company.id,
        },
      }
      return request.executeGet(`${urlRutas}?filter=${JSON.stringify(filter)}`)
    }

    const filter = {
      where: {
        companyId: usuario.company.id,
      },
    }

    let finalURL = url

    // TODO: todos los catalogos antiguos se deben de migrar a esto
    if (!mostrarTodos) {
      filter.where.active = true
    }

    if (tipo === 10) {
      filter.where.tipo = 'AMPLIACION'
    }

    if (tipo === 30) {
      filter.where.tipo = 'LEVANTAMIENTO'
    }

    if (tipo === 33) {
      filter.where.tipo = 'CORRECTIVO'
    }

    finalURL = `${url}?filter=${JSON.stringify(filter)}`
    return request.getList(finalURL, catalogo)
  } catch (err) {
    console.error(`Error en cargar ${catalogo}`, err)
    return []
  }
}

export async function getItem(tipo, id) {
  try {
    const request = new RequestAdmin()
    let url = getUrlTipo(tipo)
    if (tipo === 8) {
      url = urlRutas
    }
    return request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Item', err)
    return []
  }
}

export async function getDatosInformeAmpliacion() {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${getUrlTipo(10)}/nombres-reporte`)
  } catch (err) {
    console.error(
      'Error obtener el listado de detalle del reporte de ampliaciones',
      err,
    )
    return null
  }
}

export async function saveItem(tipo, body, catalogo, operacion) {
  try {
    const request = new RequestAdmin()
    let url = getUrlTipo(tipo)
    // eslint-disable-next-line no-param-reassign
    delete body.precio

    if (operacion === 1 && tipo === 8) {
      // eslint-disable-next-line no-param-reassign
      body = JSON.parse(body)
      const user = JSON.parse(localStorage.getItem('userData'))
      // eslint-disable-next-line no-param-reassign
      if (!body.tipoRuta) body.tipoRuta = 'NOCTURNA'
      // eslint-disable-next-line no-param-reassign
      if (!body.companyId) body.companyId = user.idCompany

      // eslint-disable-next-line no-param-reassign
      if (body.userEncargado) body.userEncargado = body.userEncargado.id

      // eslint-disable-next-line no-param-reassign
      if (body.fechaProgramada === null) delete body.fechaProgramada
      url = urlRutas
      // eslint-disable-next-line no-param-reassign
      body = JSON.stringify(body)
    } else if (operacion !== 1 && tipo === 8) {
      // eslint-disable-next-line no-param-reassign
      if (body.fechaProgramada === null) delete body.fechaProgramada
      if (!body.principales && !body.secundarias) url = urlRutas
    } else if (tipo === 11) {
      // eslint-disable-next-line no-param-reassign
      body = JSON.parse(body)
      // eslint-disable-next-line no-param-reassign
      body.costoNocturno = parseFloat(body.costoNocturno) || 0
      // eslint-disable-next-line no-param-reassign
      body.precioVentaNocturno = parseFloat(body.precioVentaNocturno) || 0
    }
    if (operacion === 1) return request.executePostPutPatch(url, body, 'POST')
    // eslint-disable-next-line no-param-reassign
    if (body.active) body.active = body.active.value
    return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
  } catch (err) {
    console.error(`Error en Guardar/Actualizar ${catalogo}`, err)
    return null
  }
}
