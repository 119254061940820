<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-asignar
      variant="relief-primary"
      block
      @click="verDetallePoste"
    >
      Detalle del Punto
    </b-button>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      title="Punto de Iluminación"
    >
      <b-card class="card-poste">
        <detail-punto :id="id" :punto="punto" />
      </b-card>
    </b-modal>
  </div>

</template>

<script>
import { BModal, BCard, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailPunto from '@/components/PuntosIluminacion/DetailPunto.vue'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BCard,
    BButton,
    DetailPunto,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    punto: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    verDetallePoste() {
      this.ocultarDialogo()
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}

.card-poste .text {
  color: #211f1f70;
}
</style>
