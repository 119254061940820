import config from '@/utils/config'
import RequestAdmin from '@/utils/request'

const url = `${config.URL_BASE}admin-gabinetes`
export async function updateCreateAdminGabinete(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Gabinete', err)
    return null
  }
}

export async function findAdminGabinete(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Gabinetes', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getAdminGabinete(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Gabinete', err)
    return null
  }
}
