<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="primary"
      style="margin-top: 23px"
      block
      @click="showFalla()"
    >
      {{ buttonText }}
    </b-button>
    <!-- Crear ticket -->
    <b-modal
      v-model="verTicket"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-only
      ok-variant="primary"
      cancel-variant="danger"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      :hide-footer="origenData === 'bodega'"
      :title="modalTitulo"
    >
      <ingreso-busqueda
        :origen-data="origenData"
        :placeholder="placeholder"
        @cerrar-dialog="ocultarDialogo"
        @agregar-poste="emitirNuevoPoste"
      />
      <hr
        v-if="origenData === 'bodega'"
        style="margin-bottom: -10px"
      >
      <div
        v-if="origenData === 'bodega'"
        style="margin-right: -15px"
        class="demo-inline-spacing justify-content-end"
      >
        <b-button
          v-if="origenData === 'bodega'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="relief-primary"
          :to="{ name: 'recepcion-bodega', query: { action: 'Create' }}"
        >
          Ingresar Sin Luminaria Existente
        </b-button>
        <b-button
          variant="primary"
          @click="verTicket = false"
        >
          Aceptar
        </b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import IngresoBusqueda from '@/components/ModaIngresoFormularios/IngresoBusqueda.vue'

export default {
  components: {
    IngresoBusqueda,
    BModal,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    origenData: {
      type: String,
      default: 'gateway',
    },
    buttonText: {
      type: String,
      default: 'Ingresar Conservación',
    },
    modalTitulo: {
      type: String,
      default: 'Ingresar Conservación',
    },
    placeholder: {
      type: String,
      default: 'Buscar Puntos de Iluminación...',
    },
  },
  data() {
    return {
      verTicket: false,
      opcion: '',
    }
  },
  methods: {
    showFalla() {
      this.ocultarDialogo()
      this.verTicket = true
    },
    ocultarDialogo() {
      this.verTicket = false
      this.$emit('refrescar-pagina')
    },
    emitirNuevoPoste(punto) {
      this.$emit('agregar-poste', punto)
    },
  },
}
</script>

<style scoped>
</style>
