<template>
  <div>
    <b-overlay
      opacity="0.80"
      variant="transparent"
      blur="0.40rem"
      :show="show"
    >
      <template #overlay>
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <b-card class="mb-0">
            <b-row>
              <b-col cols="10">
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="icons-search"
                      v-model="seachQuery"
                      :placeholder="placeholder"
                      @keyup.enter="searchData()"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  title="Buscar"
                  variant="primary"
                  class="btn-icon"
                  @click="searchData()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- Mantenimiento Luminarias -->
        <b-col
          style="margin-top: 10px; margin-bottom: -50px"
          cols="12"
        >
          <div v-if="origenData === 'bodega'">
            <b-row>
              <b-col
                v-for="(punto) in items"
                :key="punto.id"
                cols="12"
              >
                <b-card-group class="mb-0">
                  <div v-if="datosEncontrados === 'POSTES'">
                    <b-card
                      class="text-center"
                      footer-class="text-muted"
                    >
                      <b-card-body>
                        <h4
                          style="font-weight: bold; margin-top: -25px"
                          class="text-primary"
                        >
                          PUNTOS DE ILUMINACIÓN
                        </h4>
                        <hr style="margin-top: -1px">
                        <b-row>
                          <b-col cols="4">
                            <h5>No. Poste:</h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.numeroPoste"
                              readonly
                              style="margin-top: -5px;"
                            />
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="origenData !== 'bodega'"
                          style="margin-top: 5px;"
                        >
                          <b-col cols="4">
                            <h5>Grupo:</h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.objGrupo.nombre"
                              readonly
                            />
                          </b-col>
                        </b-row>
                        <b-row style="margin-top: 5px;">
                          <b-col cols="4">
                            <h5>Serie Lampara:</h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.serieLampara"
                              readonly
                            />
                          </b-col>
                        </b-row>
                        <b-row style="margin-top: 5px;">
                          <b-col cols="4">
                            <h5>Número Contador:</h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.numeroContador"
                              readonly
                            />
                          </b-col>
                        </b-row>
                        <b-row style="margin-top: 7px;">
                          <b-col cols="4">
                            <h5>Potencia: </h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.potencia"
                              readonly
                              style="margin-top: -8px;"
                            />
                          </b-col>
                        </b-row>
                        <b-row style="margin-top: 20px;">
                          <b-col cols="6">
                            <modal-detail-poste :id="punto.id" />
                          </b-col>
                          <b-col cols="6">
                            <b-button
                              v-if="origenData === 'coordinadora'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'coordinacion-aseguradora-formulario', params: { poste: punto.id} }"
                            >
                              Ingresar Nueva Coordinación
                            </b-button>
                            <b-button
                              v-if="origenData === 'conservacion'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'conservacion-formulario', params: { poste: punto.id} }"
                            >
                              Ingresar Conservación
                            </b-button>
                            <b-button
                              v-if="origenData === 'brecha'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'brecha-iluminacion-formulario', params: { brecha: $route.params.brecha || punto.id}, query: { action: 'Create' } }"
                            >
                              Ingresar Brecha de Iluminación
                            </b-button>
                            <b-button
                              v-if="origenData === 'aplomado'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'aplomado-poste-formulario', params: { poste: punto.id}, query: { action: 'Create' } }"
                            >
                              Ingresar Aplomado de Poste
                            </b-button>
                            <modal-ingreso-bodega
                              v-if="origenData === 'bodega'"
                              :punto-iluminacion="punto"
                              @cerrar-ingreso="ocultarDialogo"
                            />
                            <b-button
                              v-if="origenData === 'nuevoPoste'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              @click="agregarNuevoPoste(punto)"
                            >
                              Agregar Nuevo Poste
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </div>
                  <div v-else>
                    <b-card
                      style="height: 430px"
                      class="text-center"
                      footer-class="text-muted"
                    >
                      <b-card-body>
                        <b-row>
                          <b-col cols="12">
                            <h4
                              style="font-weight: bold; margin-top: -25px"
                              class="text-primary"
                            >
                              LUMINARIA EN MÓDULO LUMINARIAS
                            </h4>
                            <hr style="margin-top: -1px">
                            <h5>Estado</h5>
                            <b-alert
                              :variant="getColorVariant(punto)"
                              show
                            >
                              <div class="alert-body">
                                <center>
                                  <span>
                                    <strong> {{ estadoSeguimiento(punto.estado) }}</strong>
                                  </span>
                                </center>
                              </div>
                            </b-alert>
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <b-form-group>
                              <h5>Tipo Lámpara</h5>
                              <b-form-input
                                class="text-center uneditable-input"
                                disabled
                                :value="punto.tipoLampara.nombre"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group>
                              <h5>Serie</h5>
                              <b-form-input
                                class="text-center uneditable-input"
                                disabled
                                :value="punto.serie"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="6"
                          >
                            <b-form-group>
                              <h5>Tipo</h5>
                              <b-form-input
                                class="text-center uneditable-input"
                                disabled
                                :value="punto.tipo"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group>
                              <h5>Fecha Creación</h5>
                              <b-form-input
                                class="text-center uneditable-input"
                                disabled
                                :value="formatDate(punto.fechaCreacion, '/')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <modal-ingreso-bodega
                              v-if="origenData === 'bodega'"
                              :punto-iluminacion="punto"
                              @cerrar-ingreso="ocultarDialogo"
                            />
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="origenData !== 'bodega'"
                          style="margin-top: 5px;"
                        >
                          <b-col cols="4">
                            <h5>Grupo:</h5>
                          </b-col>
                          <b-col cols="8">
                            <b-form-input
                              id="orden"
                              :value="punto.objGrupo.nombre"
                              readonly
                            />
                          </b-col>
                        </b-row>
                        <b-row style="margin-top: 20px;">
                          <b-col cols="6">
                            <b-button
                              v-if="origenData === 'coordinadora'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'coordinacion-aseguradora-formulario', params: { poste: punto.id} }"
                            >
                              Ingresar Nueva Coordinación
                            </b-button>
                            <b-button
                              v-if="origenData === 'conservacion'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'conservacion-formulario', params: { poste: punto.id} }"
                            >
                              Ingresar Conservación
                            </b-button>
                            <b-button
                              v-if="origenData === 'brecha'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'brecha-iluminacion-formulario', params: { brecha: $route.params.brecha || punto.id}, query: { action: 'Create' } }"
                            >
                              Ingresar Brecha de Iluminación
                            </b-button>
                            <b-button
                              v-if="origenData === 'aplomado'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              :to="{ name: 'aplomado-poste-formulario', params: { poste: punto.id}, query: { action: 'Create' } }"
                            >
                              Ingresar Aplomado de Poste
                            </b-button>
                            <b-button
                              v-if="origenData === 'nuevoPoste'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="relief-primary"
                              @click="agregarNuevoPoste(punto)"
                            >
                              Agregar Nuevo Poste
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </div>
                </b-card-group>
                <br>
              </b-col>
            </b-row>
          </div>
          <!-- Conservación - Brecha - Aplomado - Coordinadora - Nuevo Poste -->
          <div v-if="origenData === 'conservacion' || origenData === 'brecha' || origenData === 'aplomado' || origenData === 'coordinadora' || origenData === 'nuevoPoste'">
            <b-row>
              <b-col
                v-for="(punto) in items"
                :key="punto.id"
                cols="12"
              >
                <b-card-group class="mb-0">
                  <b-card
                    :title="`Punto de Iluminación`"
                    class="text-center"
                    footer-class="text-muted"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col cols="4">
                          <h5>No. Poste:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="punto.numeroPoste"
                            readonly
                            style="margin-top: -5px;"
                          />
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="origenData !== 'bodega'"
                        style="margin-top: 5px;"
                      >
                        <b-col cols="4">
                          <h5>Grupo:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="punto.objGrupo.nombre"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 5px;">
                        <b-col cols="4">
                          <h5>Serie Lampara:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="punto.serieLampara"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 5px;">
                        <b-col cols="4">
                          <h5>Número Contador:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="punto.numeroContador"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 7px;">
                        <b-col cols="4">
                          <h5>Potencia: </h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="punto.potencia"
                            readonly
                            style="margin-top: -8px;"
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 20px;">
                        <b-col cols="6">
                          <modal-detail-poste :id="punto.id" />
                        </b-col>
                        <b-col cols="6">
                          <b-button
                            v-if="origenData === 'coordinadora'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'coordinacion-aseguradora-formulario', params: { poste: punto.id} }"
                          >
                            Ingresar Nueva Coordinación
                          </b-button>
                          <b-button
                            v-if="origenData === 'conservacion'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'conservacion-formulario', params: { poste: punto.id} }"
                          >
                            Ingresar Conservación
                          </b-button>
                          <b-button
                            v-if="origenData === 'brecha'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'brecha-iluminacion-formulario', params: { brecha: $route.params.brecha || punto.id}, query: { action: 'Create' } }"
                          >
                            Ingresar Brecha de Iluminación
                          </b-button>
                          <b-button
                            v-if="origenData === 'aplomado'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'aplomado-poste-formulario', params: { poste: punto.id}, query: { action: 'Create' } }"
                          >
                            Ingresar Aplomado de Poste
                          </b-button>
                          <modal-ingreso-bodega
                            v-if="origenData === 'bodega'"
                            :punto-iluminacion="punto"
                            @cerrar-ingreso="ocultarDialogo"
                          />
                          <b-button
                            v-if="origenData === 'nuevoPoste'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            @click="agregarNuevoPoste(punto)"
                          >
                            Agregar Nuevo Poste
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-card-group>
                <br>
              </b-col>
            </b-row>
          </div>
          <!--Gateway-->
          <div v-if="origenData === 'gateway'">
            <b-row>
              <b-col
                v-for="(gateway) in items"
                :key="gateway.id"
                cols="12"
              >
                <b-card-group class="mb-0">
                  <b-card
                    :title="`Administrador de Gateway`"
                    class="text-center"
                    footer-class="text-muted"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col cols="4">
                          <h5>Nombre:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gateway.nombre"
                            readonly
                            style="margin-top: -8px;"
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 10px;">
                        <b-col cols="4">
                          <h5>Serie:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gateway.serie"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 5px;">
                        <b-col cols="4">
                          <h5>Red:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gateway.red"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 20px;">
                        <b-col cols="12">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'conservacion-gateway-formulario', params: { gateway: $route.params.gateway || gateway.id }, query: { action: 'Create' } }"
                          >
                            Ingresar Conservación Gateway
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-card-group>
                <br>
              </b-col>
            </b-row>
          </div>
          <!--Gabinetes-->
          <div v-if="origenData === 'gabinetes'">
            <b-row>
              <b-col
                v-for="(gabinete) in items"
                :key="gabinete.id"
                cols="12"
              >
                <b-card-group class="mb-0">
                  <b-card
                    :title="`Administrador Centros de Mando P&M`"
                    class="text-center"
                    footer-class="text-muted"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col cols="4">
                          <h5>Nombre:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gabinete.poste"
                            readonly
                            style="margin-top: -8px;"
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 10px;">
                        <b-col cols="4">
                          <h5>Serie:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gabinete.zona"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 5px;">
                        <b-col cols="4">
                          <h5>Red:</h5>
                        </b-col>
                        <b-col cols="8">
                          <b-form-input
                            id="orden"
                            :value="gabinete.direccion"
                            readonly
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 20px;">
                        <b-col cols="12">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="relief-primary"
                            :to="{ name: 'conservacion-gabinetes-formulario', params: { gabinete: $route.params.gabinete || gabinete.id,}, query: { action: 'Create' } }"
                          >
                            Ingresar Conservación Centro de Mando P&M
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-card-group>
                <br>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BAlert,
  BCardGroup,
  VBModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCardBody, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getPostesFilter } from '@/utils/postes'
import { findAdminGateway } from '@/utils/administrador/gateway'
import { findAdminGabinete } from '@/utils/administrador/gabinetes'
import ModalDetailPoste from '@/components/PuntosIluminacion/ModalDetailPoste.vue'
import ModalIngresoBodega from '@/components/mantenimiento/bodega/modal-ingreso-bodega.vue'
import { findLuminariasLab, getColorVariantLuminarias, getUtilsLaboratorio } from '@/utils/laboratorio/luminarias'
import { formatDate } from '@/utils/fechas'
import { mensajeError, notificacionError } from '@/utils/mensajes'

export default {
  components: {
    ModalIngresoBodega,
    BButton,
    BCard,
    BRow,
    BCol,
    BAlert,
    BOverlay,
    BInputGroup,
    BCardGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BCardBody,
    ModalDetailPoste,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    origenData: {
      type: String,
      default: 'gabinete',
    },
    placeholder: {
      type: String,
      default: 'Buscar Puntos de Iluminación...',
    },
  },
  data() {
    return {
      seachQuery: '',
      items: [],
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      poste: null,
      estados: '',
      datosEncontrados: null,
      origen: router.currentRoute.params.tipo,
      id: router.currentRoute.params.id,
    }
  },
  created() {
    this.estados = getUtilsLaboratorio(3)
    if (!this.origen) this.origen = this.usuario.role
  },
  methods: {
    getColorVariant(item) {
      return getColorVariantLuminarias(item)
    },
    formatDate,
    agregarNuevoPoste(punto) {
      this.$emit('agregar-poste', punto)
      this.$emit('cerrar-dialog')
    },
    ocultarDialogo() {
      this.$emit('cerrar-dialog')
    },
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    async searchData() {
      try {
        this.show = true
        if (this.seachQuery === '') {
          mensajeError('Ingrese un valor de referencia del poste, serie o contador!')
          return
        }
        if (this.origenData === 'bodega') {
          let filter
          filter = {
            where: {
              and: [
                { company: this.usuario.company.id },
                {
                  or: [
                    { numeroPoste: { like: this.seachQuery.trim(), options: 'i' } },
                    { serieLampara: { like: this.seachQuery.trim(), options: 'i' } },
                    { numeroContador: { like: this.seachQuery.trim(), options: 'i' } },
                  ],
                },
              ],
            },
          }
          this.items = await getPostesFilter(filter)
          this.datosEncontrados = 'POSTES'
          if (this.items.length === 0) {
            filter = {
              where: {
                and: [
                  { companyId: this.usuario.company.id },
                  {
                    or: [
                      { serie: { like: this.seachQuery.trim(), options: 'i' } },
                    ],
                  },
                ],
              },
            }
            this.items = await findLuminariasLab(filter)
            this.datosEncontrados = 'LUMINARIAS'
          }
        } else if (this.origenData === 'conservacion' || this.origenData === 'brecha' || this.origenData === 'aplomado' || this.origenData === 'coordinadora' || this.origenData === 'nuevoPoste') {
          const filter = {
            where: {
              and: [
                { company: this.usuario.company.id },
                {
                  or: [
                    { numeroPoste: { regexp: this.seachQuery } },
                    { serieLampara: { regexp: this.seachQuery } },
                    { numeroContador: { regexp: this.seachQuery } },
                  ],
                },
              ],
            },
          }
          this.items = await getPostesFilter(filter)
        } else if (this.origenData === 'gateway') {
          const filter = {
            where: {
              and: [
                { companyId: this.usuario.company.id },
                {
                  or: [
                    { nombre: { regexp: this.seachQuery } },
                  ],
                },
              ],
            },
          }
          this.items = await findAdminGateway(filter)
        } else if (this.origenData === 'gabinetes') {
          const filter = {
            where: {
              and: [
                { companyId: this.usuario.company.id },
                {
                  or: [
                    { poste: { regexp: this.seachQuery } },
                  ],
                },
              ],
            },
          }
          this.items = await findAdminGabinete(filter)
        }
        if (this.items.length === 0) {
          mensajeError('No hay resultados para esta búsqueda.')
          notificacionError('Error', 'No hay resultados para esta búsqueda.')
        }
      } catch (error) {
        console.error(error)
        notificacionError('Error', 'Ha ocurrido un error al realizar la búsqueda. 😨')
      } finally {
        this.show = false
      }
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
.uneditable-input {
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ced4da; /* Light border */
}
</style>
