<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="3"
      >
        <b-form-group>
          <h6 class="text-primary">
            Fecha
          </h6>
          <b-form-input
            :value="formatFecha(aplomado.fechaCreacion)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            No. Poste
          </h6>
          <b-form-input
            :value="aplomado.poste"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="5"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brigada
          </h6>
          <b-form-input
            :value="aplomado.brigada.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Lugar
          </h6>
          <b-form-textarea
            :value="aplomado.lugar"
            disabled
            no-resize
            rows="2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h3 class="text-primary">
      Trabajos de Montaje y Desmontaje
    </h3>
    <hr>
    <b-row>
      <b-col
        v-if="aplomado.trabajosMontaje && aplomado.trabajosMontaje.length > 0"
        cols="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Trabajos de Montaje
          </h6>
          <b-form-input
            :value="obtenerTrabajosMontaje()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="aplomado.trabajosDesmontaje && aplomado.trabajosDesmontaje.length > 0"
        cols="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Trabajos de Desmontaje
          </h6>
          <b-form-input
            :value="obtenerTrabajosDesmontaje()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="aplomado.aplomadoPoste && aplomado.aplomadoPoste"
        cols="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Aplomado de Poste
          </h6>
          <b-form-textarea
            :value="aplomado.aplomadoPoste"
            disabled
            rows="2"
            no-resize
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="aplomado.seguimiento"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-if="props.row.url"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarImagen(props.row)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'comentarios'">
              <span>{{ props.row.comentarios }}</span>
            </span>
            <span v-else-if="props.column.field == 'fechaHora'">
              <span>{{ formatFecha(aplomado.fechaCreacion) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow, BCol, BFormGroup, BButton, BModal, VBModal, BCardText, BImg, BFormTextarea,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { calcularFecha } from '@/utils/fechas'

export default {
  components: {
    VueGoodTable,
    BCardText,
    BFormInput,
    BFormGroup,
    BModal,
    BImg,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    aplomado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlImagen: '',
      verDialogoDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      rows: [],
    }
  },
  methods: {
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    obtenerTrabajosMontaje() {
      if (this.aplomado && this.aplomado.trabajosMontaje) {
        return this.aplomado.trabajosMontaje.map(trabajo => trabajo.nombre).join(', ')
      }
      return ''
    },
    obtenerTrabajosDesmontaje() {
      if (this.aplomado && this.aplomado.trabajosDesmontaje) {
        return this.aplomado.trabajosDesmontaje.map(trabajo => trabajo.nombre).join(', ')
      }
      return ''
    },
  },
}
</script>
