// eslint-disable-next-line import/prefer-default-export
import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export function formatoFecha(fecha) {
  return moment(fecha).format('DD/MM/YYYY, HH:mm:ss')
}

export function convertDateTimeToDate(datetime) {
  const dateStr = new Date(datetime)
  return dateStr.toLocaleDateString()
}

export function formatDate(date, simbol = '-') {
  const d = new Date(date)
  const options = { timeZone: 'UTC' }
  const dateString = d.toLocaleString('es-GT', options)
  const [datePart, timePart] = dateString.split(' ')

  let month = `${d.getUTCMonth() + 1}`
  let day = `${d.getUTCDate()}`
  const year = d.getUTCFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  if (simbol !== '-') return [day, month, year].join(simbol)
  return [year, month, day].join(simbol)
}

export function sumarDiasFecha(fecha, dias) {
  fecha.setDate(fecha.getDate() + dias)
  return fecha
}

// eslint-disable-next-line import/prefer-default-export
export function obtenerFechaSemana() {
  const now = new Date()
  let diaInicial = new Date()
  diaInicial = sumarDiasFecha(diaInicial, -(now.getDay() - 1))
  let diaFinal = new Date()
  diaFinal = sumarDiasFecha(diaFinal, (7 - now.getDay()))
  return {
    diaInicial,
    diaFinal,
  }
}

export function diferenceBetweenToday(date) {
  const date1 = new Date(date)
  const date2 = new Date()
  const diffTime = Math.abs(date2 - date1)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export function diferenceBetweenTodayNoAbs(date) {
  const x = new Date(date)
  const y = new Date()
  return Math.floor((x - y) / (1000 * 60 * 60 * 24))
}

export function calcularMinutosTranscurridos(fechaString, now = null) {
  const fechaInicio = new Date(fechaString)
  const actual = now || new Date()
  const dif = actual.getTime() - fechaInicio.getTime()
  const segundos = dif / 1000 / 60
  return Math.round(Math.abs(segundos))
}

export function calcularFecha(fechaString) {
  const date = new Date(fechaString)
  return date.toLocaleString('es-GT')
}

export function obtenerFechaInicioFin(moths = 0) {
  const date = new Date()
  const primerDia = new Date(date.getFullYear(), date.getMonth(), 1)
  // Se crea una fecha con el mes siguiente y día 0, que automáticamente da el último día del mes actual
  const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  let mes = (primerDia.getMonth() + 1 + moths).toString()
  if (mes.length === 1) mes = `0${mes}`

  // Se utiliza el mes del ultimoDia para el mes de fin
  let mesFin = (ultimoDia.getMonth() + 1).toString()
  if (mesFin.length === 1) mesFin = `0${mesFin}`

  // Se corrige la fecha de fin para que sea el primer día del mes siguiente
  const primerDiaMesSiguiente = new Date(ultimoDia.getFullYear(), ultimoDia.getMonth() + 1, 1);
  let mesInicioSiguiente = (primerDiaMesSiguiente.getMonth() + 1).toString();
  if (mesInicioSiguiente.length === 1) mesInicioSiguiente = `0${mesInicioSiguiente}`;

  return {
    fechaInicio: `${primerDia.getFullYear()}-${mes}-01`,
    fechaFin: `${primerDiaMesSiguiente.getFullYear()}-${mesInicioSiguiente}-01`,
  }
}

export function getMinutes(row) {
  const creation = (row.imagenes && row.imagenes.length > 0) ? new Date(row.imagenes[0].fechaHora) : new Date(row.fechaCreacion)
  const inicios = row.trackingEstados ? row.trackingEstados.filter(e => e.estado === 'VERIFICADO') : []
  const inicio = inicios.length > 0 ? new Date(inicios[0].horaFecha) : creation
  return calcularMinutosTranscurridos(inicio)
}

export function getDays(minutos) {
  if (minutos === 1) return `${minutos} minuto en progreso`
  if (minutos < 60) {
    return `${minutos} minutos en progreso`
  }
  const horas = Math.round(minutos / 60)
  if (horas === 1) return `${horas} hora en progreso`
  return `${horas} horas en progreso`
}

export function getClassFooter(row, minutos) {
  if (row.imagenes && row.imagenes.length > 0) {
    const horas = Math.round(minutos / 60)
    if (horas < 6) return 'text-success'
    if (horas >= 6 && horas < 10) return 'text-secondary'
    if (horas >= 10 && horas < 12) return 'text-warning'
    return 'text-danger'
  }
  return 'text-muted'
}

const años = [
  {
    id: '2022',
    nombre: '2022',
  },
  {
    id: '2023',
    nombre: '2023',
  },
  {
    id: '2024',
    nombre: '2024',
  },
  {
    id: '2025',
    nombre: '2025',
  },
  {
    id: '2026',
    nombre: '2026',
  },
  {
    id: '2027',
    nombre: '2027',
  },
  {
    id: '2028',
    nombre: '2028',
  },
]

const meses = [
  {
    id: '1',
    nombre: 'Enero',
    dia: 31,
  },
  {
    id: '2',
    nombre: 'Febrero',
    dia: 29,
  },
  {
    id: '3',
    nombre: 'Marzo',
    dia: 31,
  },
  {
    id: '4',
    nombre: 'Abril',
    dia: 30,
  },
  {
    id: '5',
    nombre: 'Mayo',
    dia: 31,
  },
  {
    id: '6',
    nombre: 'Junio',
    dia: 30,
  },
  {
    id: '7',
    nombre: 'Julio',
    dia: 31,
  },
  {
    id: '8',
    nombre: 'Agosto',
    dia: 31,
  },
  {
    id: '9',
    nombre: 'Septiembre',
    dia: 30,
  },
  {
    id: '10',
    nombre: 'Octubre',
    dia: 31,
  },
  {
    id: '11',
    nombre: 'Noviembre',
    dia: 30,
  },
  {
    id: '12',
    nombre: 'Diciembre',
    dia: 31,
  },
]

export function getListadoMeses(tipo) {
  if (tipo === 1) return meses
  if (tipo === 2) return años
  return []
}
