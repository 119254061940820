<template>
  <div class="container-input">
    <input
      :id="leyenda"
      type="file"
      accept="image/*"
      :name="leyenda"
      class="inputfile inputfile-1"
      @change="onFileUpload"
    >
    <label :for="leyenda">
      <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 392.663 392.663"
        style="enable-background:new 0 0 392.663 392.663;"
        xml:space="preserve"
      >
        <path
          style="fill:#FFFFFF;"
          d="M360.986,113.293H98.198v223.224h262.788c5.301,0,9.632-4.331,9.632-9.632V122.99
	C370.747,117.624,366.416,113.293,360.986,113.293z"
        />
        <rect
          x="120.048"
          y="135.079"
          style="fill:#56ACE0;"
          width="228.913"
          height="179.652"
        />
        <path
          style="fill:#FFC10D;"
          d="M259.103,160.743c-35.362,0-64.129,28.768-64.129,64.129s28.768,64.129,64.129,64.129
	s64.129-28.768,64.129-64.129S294.465,160.743,259.103,160.743z"
        />
        <g>
          <path
            style="fill:#194F82;"
            d="M259.103,140.897c-46.352,0-83.976,37.689-83.976,83.976s37.689,83.976,83.976,83.976
		s83.976-37.624,83.976-83.976S305.39,140.897,259.103,140.897z M259.103,289.131c-35.362,0-64.129-28.768-64.129-64.129
		s28.768-64.129,64.129-64.129s64.129,28.768,64.129,64.129S294.465,289.131,259.103,289.131z"
          />
          <path
            style="fill:#194F82;"
            d="M259.103,173.802c-6.012,0-10.925,4.848-10.925,10.925s4.848,10.925,10.925,10.925
		c16.162,0,29.285,13.123,29.285,29.285c0,6.012,4.848,10.925,10.925,10.925c6.012,0,10.925-4.848,10.925-10.925
		C310.174,196.752,287.224,173.802,259.103,173.802z"
          />
        </g>
        <path
          style="fill:#56ACE0;"
          d="M21.786,326.82c0,5.301,4.331,9.632,9.632,9.632h44.994v-223.16H31.547
	c-5.301,0-9.632,4.331-9.632,9.632"
        />
        <polygon
          style="fill:#FFC10D;"
          points="316.574,91.507 301.059,56.081 217.147,56.081 201.568,91.507 "
        />
        <rect
          x="75.83"
          y="72.113"
          style="fill:#FFFFFF;"
          width="27.798"
          height="19.394"
        />
        <g>
          <path
            style="fill:#194F82;"
            d="M360.986,91.507h-20.622l-22.238-50.683c-1.745-3.943-5.624-6.465-9.956-6.465h-98.133
		c-4.331,0-8.275,2.521-9.956,6.465l-22.238,50.683h-52.299v-20.04c0-11.636-9.503-21.204-21.204-21.204H75.313
		c-11.636,0-21.204,9.503-21.204,21.204v20.04H31.547C14.158,91.507,0,105.6,0,122.925v203.83c0,17.39,14.158,31.547,31.547,31.547
		h329.568c17.39,0,31.547-14.158,31.547-31.547V122.99C392.533,105.6,378.376,91.507,360.986,91.507z M370.747,326.82
		c0,5.301-4.331,9.632-9.632,9.632H98.198v-223.16h262.788c5.301,0,9.632,4.331,9.632,9.632v203.83h0.129V326.82z M76.412,336.517
		H31.547c-5.301,0-9.632-4.331-9.632-9.632v-70.4h13.576c6.012,0,10.925-4.848,10.925-10.925c0-6.077-4.848-10.925-10.925-10.925
		H21.786V215.24h30.707c6.012,0,10.925-4.848,10.925-10.925S58.57,193.39,52.493,193.39H21.786v-70.271
		c0-5.301,4.331-9.632,9.632-9.632h44.994V336.517z M75.83,72.113h27.798v19.394H75.83V72.113z M301.059,56.081l15.515,35.362
		H201.568l15.515-35.362H301.059z"
          />
          <path
            style="fill:#194F82;"
            d="M179.2,150.659c0-6.012-4.848-10.925-10.925-10.925h-37.301c-6.012,0-10.925,4.848-10.925,10.925
		c0,6.012,4.848,10.925,10.925,10.925h37.301C174.222,161.519,179.2,156.671,179.2,150.659z"
          />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
      <span
        v-if="file === ''"
        class="iborrainputfile"
      >Tomar Foto</span>
    </label>
  </div>
</template>

<script>

export default {
  name: 'Imagen',
  props: {
    leyenda: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: '',
      name: '',
    }
  },
  methods: {
    onFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        this.file = file
        this.reduceCalidadImagen(file, 0.4)
      }
    },
    reduceCalidadImagen(file, calidadDeseada) {
      const img = new Image()

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = img.width
        canvas.height = img.height

        ctx.drawImage(img, 0, 0)

        canvas.toBlob(blob => {
          const nuevaImagen = new File([blob], file.name, { type: 'image/jpeg' })
          this.$emit('cargar-file', nuevaImagen)
        }, 'image/jpeg', calidadDeseada)
      }

      const reader = new FileReader()
      reader.onload = e => {
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style scoped>
.container-input {
  text-align: center;
  margin-top: 5px;
  border-radius: 6px;
  width: 100%;
  margin: 0 auto;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.inputfile + label svg {
  width: 4em;
  height: 4em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.iborrainputfile {
  font-size: 16px;
  font-weight: normal;
}

/* style 1 */

.foto + label {
  color: #fff;
  background-color: #c39f77;
}

.foto:focus + label,
.foto.has-focus + label,
.foto + label:hover {
  background-color: #9f8465;
}

</style>
